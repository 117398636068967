var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-table","fluid":"","tag":"section"}},[_c('participants-form',{ref:"editForm",attrs:{"gid":_vm.gid,"data":_vm.meta},on:{"itemSaved":_vm.onSaved}}),_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('group.Delete participant'),"confirmation":_vm.$t('group.participant deleted')},on:{"itemDeleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v(_vm._s(_vm.$t("group.confirm delete participant", { name: item.name })))]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{
    'items-per-page-options': [20, 50, 100],
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
  var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.age",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.birth_year ? _vm.year - item.birth_year : "-")+" ")]}},{key:"item.gender",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.gender[item.gender])+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),(!_vm.gid)?_c('v-btn',{staticClass:"mb-2 mr-5",attrs:{"color":"primary","dark":""},on:{"click":_vm.editGroups}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(" "+_vm._s(_vm.$t("group.groups"))+" ")],1):_vm._e(),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("group.Add participant"))+" ")],1)],1)]},proxy:true},{key:"item.birth_date",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.birth_date ? _vm.moment(item.birth_date).format("DD.MM.Y") : "")+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onOpen(item)}}},[_vm._v("mdi-eye")]),(_vm.gid)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-link-variant-minus")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }