<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-account-multiple" :title="$t('group.participants')">
      <participants-table
      ></participants-table>
    </base-panel>
  </v-container>
</template>

<script>
import ParticipantsTable from "@/pages/clients/ParticipantsTable";

export default {
  components: {
    ParticipantsTable,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
