<template>
  <v-container id="user-table" fluid tag="section">
    <participants-form v-bind:gid="gid" :data="meta" ref="editForm" @itemSaved="onSaved"></participants-form>
    <delete-dialog ref="deleteConfirm" :url="apiQuery" :title="$t('group.Delete participant')"
      :confirmation="$t('group.participant deleted')" @itemDeleted="onDeleted">
      <template v-slot:default="item">{{
      $t("group.confirm delete participant", { name: item.name })
    }}</template>

      <template v-slot:error="error">
        <v-alert v-if="error.message" tile class="mb-0" type="error">{{
      error.message
    }}</v-alert>
      </template>
    </delete-dialog>
    <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="total" :footer-props="{
      'items-per-page-options': [20, 50, 100],
    }">
      <template v-slot:[`item.id`]="{ item }">
        <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
        <span v-else>{{ item.id }}</span>
      </template>

      <template v-slot:[`item.age`]="{ item }">
        {{ item.birth_year ? year - item.birth_year : "-" }}
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        {{ gender[item.gender] }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />
          <v-btn color="primary" dark class="mb-2 mr-5" @click="editGroups" v-if="!gid">
            <v-icon left>mdi-account-group</v-icon>
            {{ $t("group.groups") }}
          </v-btn>
          <v-btn color="primary" dark class="mb-2" @click="onAdd">
            <v-icon left>mdi-account-plus</v-icon>
            {{ $t("group.Add participant") }}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.birth_date`]="{ item }">
        {{ item.birth_date ? moment(item.birth_date).format("DD.MM.Y") : "" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="onOpen(item)">mdi-eye</v-icon>
        <v-icon small v-if="gid" @click="onDelete(item)">mdi-link-variant-minus</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import moment from "moment";

import ParticipantsForm from "@/pages/clients/ParticipantsForm";
import DeleteDialog from "@/components/crud/DeleteDialog";
export default {
  components: {
    DeleteDialog,
    ParticipantsForm,
  },
  props: ["gid", "refresh"],
  mixins: [crudMixin],

  data() {
    return {
      apiQuery: "/participant/",
      year: new Date().getFullYear(),
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("lname"), value: "lname" },
        { text: this.$t("fname"), value: "name" },
        { text: this.$t("group.email"), value: "email" },
        { text: this.$t("norm.gender"), value: "gender" },
        { text: this.$t("birthdate"), value: "birth_date" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      gender: {
        male: this.$t("gender_male"),
        female: this.$t("gender_female"),
        other: this.$t("gender_other")
      },
    };
  },
  watch: {
    gid: function () {
      this.options.group_id = this.gid;
    },
  },
  mounted() {
    this.options.group_id = this.gid;
    this.loadMeta();
  },
  methods: {
    moment,
    onDelete(item) {
      this.$refs.deleteConfirm.setParams({ group_id: this.gid });
      this.$refs.deleteConfirm.deleteItem(item, this.items.indexOf(item));
    },
    editGroups() {
      this.$router.push("/clients/groups");
    },
    onOpen(item) {
      this.$router.push("/clients/details/" + item.id);
    },
  },
};
</script>
